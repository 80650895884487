.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    padding: 2rem 6rem;
    width: 100%;
    left: 0;
    top: 0;
    transition: all 250ms ease-in-out;
    z-index: 9;
    a {
        display: flex;
        align-items: center;
        gap: 1rem;
        font-size: 1.5rem;
        color: #fff;
        text-decoration: none;
        transition: all 250ms ease-in-out;
       
        &:hover {
            filter:drop-shadow( 0 0 15px rgba(8, 255, 201, 0.95));
        }
    }
    .icon {
        display: none;
    }
}

@media screen and (max-width:1024px) {
    .navbar {
        gap: 1rem;
        justify-content: space-between;
        padding: 2rem 3rem !important;
        
        a {
            display: flex;
            align-items: center;
            gap: 1rem;
            font-size: 1.2rem;
            color: #fff;
            text-decoration: none;
            img {
                width: 50px;
            }
        }
        button {
            display: none;
        }
        .icon {
            display: block;
        }
        
    }
}
@media screen and (max-width:768px) {
    .navbar {
        gap: 1rem;
        justify-content: space-between;
        padding: 1.5rem !important;
        
        a {
            font-size: 1rem;
           
          
        }
        button {
            display: none;
        }
        .icon {
            display: block;
            cursor: pointer;
        }
        
    }
}
