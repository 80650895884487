.error {
    display: flex;
    flex-direction:column;
    gap: 3rem;
    justify-content: center;
    min-height: 80vh;
    align-items: center;
    h2 {
        font-size: 3rem;
        color: rgba(8, 255, 201, 0.95);
    }
    p {
        font-size: 1.2rem;
        color: #fff;
    }
}