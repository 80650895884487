.loader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100vh;
  position: relative;
  background-image: url("../../assets/loaderbg.svg");
  background-color: rgba(19, 22, 35, 1);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  z-index: 9999;
  .loading {
    position: relative;
  }
  img {
    position: absolute;
    top: 53%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 99;
  }
  > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 350px;
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 3rem;
    > div {
      margin: 0 auto;
      //filter:invert(1) hue-rotate(70deg);
    }
  }
  .logocontainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem;
    position: absolute;
    left: 6rem;
    top: 2.3rem;
    width: auto;
    height: auto;
    img {
      all: unset;
    }
    p {
      font-size: 1.2rem;
      margin: 0;
    }
  }
  h2 {
    font-size: 2rem;
    text-align: center;
    font-weight: normal;
  }
}

@-webkit-keyframes plasma-2 {
  0% {
    -webkit-transform: scale(1) translate3d(60px, 0, 0);
    transform: scale(1) translate3d(60px, 0, 0);
  }
  50% {
    -webkit-transform: scale(2) translate3d(-60px, 0, 0);
    transform: scale(2) translate3d(-60px, 0, 0);
  }
  100% {
    -webkit-transform: scale(1) translate3d(60px, 0, 0);
    transform: scale(1) translate3d(60px, 0, 0);
  }
}

@keyframes plasma-2 {
  0% {
    -webkit-transform: scale(1) translate3d(60px, 0, 0);
    transform: scale(1) translate3d(60px, 0, 0);
  }
  50% {
    -webkit-transform: scale(2) translate3d(-60px, 0, 0);
    transform: scale(2) translate3d(-60px, 0, 0);
  }
  100% {
    -webkit-transform: scale(1) translate3d(60px, 0, 0);
    transform: scale(1) translate3d(60px, 0, 0);
  }
}

.plasma-2 {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 20;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  width: 250px;
  height: 250px;
  padding: 0;
  -webkit-box-shadow: 0 0 30px 0 #00ddff inset;
  box-shadow: 0 0 30px 0 #00ddff inset;
  margin: auto;
  .gooey-container {
    overflow: hidden;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%) translate3d(0,0,0);
    -webkit-filter: url(#gooey-plasma-2);
    filter: url(#gooey-plasma-2);
   
    width: 300px;
    height: 300px;
    padding: 0;
    margin: 0;
    -webkit-box-shadow: 0 0 0 20px #0099ff inset;
    box-shadow: 0 0 0 20px #0099ff inset;

    .bubble {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      text-align: right;
      &:before {
        content: "";
        display: inline-block;
        background: #4081ff;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        -webkit-transform: scale(1) translate3d(75px, 0, 0);
        transform: scale(1) translate3d(75px, 0, 0);
        -webkit-box-shadow: 0 0 10px 5px #00ffff inset, 0 0 10px 0 #81ff00 inset;
        box-shadow: 0 0 10px 5px #00ffff inset, 0 0 10px 0 #81ff00 inset;
      }
    }
    @for $i from 1 through 6 {
      .bubble:nth-child(#{$i}) {
        -webkit-transform: translate(-50%, -50%) rotate(60deg * $i);
        -ms-transform: translate(-50%, -50%) rotate(60deg * $i);
        transform: translate(-50%, -50%) rotate(60deg * $i);
      }
      .bubble:nth-child(#{$i}):before {
        -webkit-animation: plasma-2 5s * ($i / 2) ease 0.5s / $i infinite;
        animation: plasma-2 5s * ($i / 2) ease 0.5s / $i infinite;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .loader {
    .logocontainer {
      left: 3rem;
      top: 2.3rem;
      img {
        all: unset;
      }
      p {
        font-size: 1.2rem;
      }
    }
    h2 {
      font-size: 1.5rem;
    }
  }
}
@media screen and (max-width: 768px) {
  .loader {
    .logocontainer {
      left: 1.5rem;
    }
  }
}
