.glowonhover {
    width: 120px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    border: none;
    outline: none;
    color: #fff;
    background: transparent;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 25px;
    border: 1px solid #ffffff;
    font-weight: bold;
    svg {
        font-size: 1.2rem;
    }
}

.glowonhover:before {
    content: '';
    background: linear-gradient(
  45deg,
  hsl(60, 80%, 80%),
  hsl(120, 80%, 80%),
  hsl(210, 80%, 80%),
  hsl(280, 80%, 80%),
  hsl(320, 80%, 80%),
  hsl(360, 80%, 80%),
  hsl(60, 80%, 80%)
);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 2s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 25px;
  
}

.glowonhover:active {
    color: #000
}

.glowonhover:active:after {
    background: transparent;
}

.glowonhover:hover:before {
    opacity: 1;
}
.glowonhover:hover:after {
    background: #000;
} 
.glowonhover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    left: 0;
    top: 0;
    border-radius: 25px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}