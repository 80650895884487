.container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
    canvas {
        transition: all 0.5s ease;
        transform: scale(1.2);
    }
    &:hover {
        canvas {
            transform: scale(0.9);
        }
    }
    .image {
        position: absolute;
        cursor: pointer;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
    }
}
@media screen and (max-width:1024px) {
    .container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        overflow: hidden;
        canvas {
            margin: 0 auto;
            width: 80vw;
            transition: all 0.5s ease;
            transform: scale(1.2);
        }
        &:hover {
            canvas {
                transform: scale(0.9);
            }
        }
        .image {
            position: absolute;
            cursor: pointer;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
        }
    }
}
@media screen and (max-width:768px) {
    .container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        overflow: auto;
        canvas {
            margin: 0 auto;
            width: 60vw;
            transition: all 0.5s ease;
            transform: scale(1);
        }
        &:hover {
            canvas {
                transform: scale(0.8);
            }
        }
        .image {
            position: absolute;
            cursor: pointer;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
        }
    }
}