.progress-wrap {
    position: fixed;
    right: 50px;
    bottom: 50px;
    height: 50px;
    width: 50px;
    cursor: pointer;
    display: block;
    border-radius: 50px;
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.2);
    z-index: 10000;
    opacity: 0;
    visibility: hidden;
    transform: translateY(15px);
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
  }

  .progress-wrap.active-progress {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  
  .progress-wrap::after {
    position: absolute;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f062';
    text-align: center;
    line-height: 50px;
    font-size: 18px;
    color: #ffffff;
    left: 0;
    top: 0;
    height: 50px;
    width: 50px;
    cursor: pointer;
    display: block;
    z-index: 1;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
  }
  
  .lightScrollIcon::after {
    color: #ecedf3 !important;
  }
  
  .progress-wrap:hover::after {
    opacity: 0;
  }
  
  .progress-wrap::before {
    position: absolute;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f062';
    text-align: center;
    line-height: 50px;
    font-size: 18px;
    opacity: 0;
    background-image: linear-gradient(298deg, rgba(8, 255, 201, 1), rgb(6, 230, 181));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    left: 0;
    top: 0;
    height: 50px;
    width: 50px;
    cursor: pointer;
    display: block;
    z-index: 2;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
  }
  
  .progress-wrap:hover::before {
    opacity: 1;
  }
  
  .progress-wrap svg path {
    fill: none;
  }
  
  .progress-wrap svg.progress-circle path {
    stroke: #ffffff;
    stroke-width: 4;
    box-sizing: border-box;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
  }
  

  
  