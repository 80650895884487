.ring {
    position: fixed;
    transition: width 0.3s, height 0.3s ease;
    top: 50%;
    left: 50%;
    width: 140px;
    height: 140px;
    background: linear-gradient(317.7deg, rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0.4) 105.18%);
    backdrop-filter: blur(5px);
    
    border: 1px solid rgb(3, 22, 57);
    border-radius: 100%;
    transform: translate(-50%, -50%);
    z-index: 999;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    .caseText {
        font-size: 1.25rem;
        font-weight: bold;
        text-align: center;
        vertical-align: middle;
    }
  }
  
@media screen and (max-width: 1024px) {
    .ring {
        display: none;
    }
}

  