.container {
    padding: 3rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('../assets/footerbg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    .innerContainer {
        display: flex;
        max-width: 1240px;
        width: 90%;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        margin: auto 0;
        .left {
            width: 100%;
            p {
                font-size: 2rem;
                font-weight: bold;
                text-transform: uppercase;
                color:  rgba(144, 159, 186, 1);
            }
            h2 {
                font-size: 4rem;
                font-weight: bold;
                font-family: 'BebasNeueBold', sans-serif;
                text-transform: uppercase;
                span {
                    color: rgba(8, 235, 182, 1);
                }
            }
            .icons {
                display: flex;
                align-items: center;
                gap: 2rem;
                margin-top: 3rem;
                color: #fff;
                font-size: 2rem;
                width: fit-content;
                a {
                    text-decoration: none;
                    color: #fff;
                     transition: all 150ms ease-in-out;
                        &:hover {
                            filter:drop-shadow( 0 0 15px rgba(255, 255, 255, 0.95));
                            transform: scale(1.1);
                        }
                }
            }
        }
      
    }
    .copy {
        max-width: 1240px;
        width: 90%;
        margin: 0 auto;

    }
}

@media screen and (max-width:1024px) {
    .container {
        padding: 3rem;
        .innerContainer {
            display: flex;
            flex-direction: column;
            justify-content:center;
            align-items: flex-start;
            height: 100%;
            margin: auto 0;
            width: 100%;
            .left {
                p {
                    font-size: 1.2rem;
                    font-weight: bold;
                    text-transform: uppercase;
                    color:  rgba(144, 159, 186, 1);
                    margin-bottom: 1rem;
                }
                h2 {
                    font-size: 3rem;
                    font-weight: bold;
                    text-transform: uppercase;
                    span {
                        color: rgba(8, 235, 182, 1);
                    }
                }
                .icons {
                    display: flex;
                    align-items: center;
                    gap: 2rem;
                    margin-top: 2rem;
                    color: #fff;
                    font-size: 2rem;
                    a {
                        text-decoration: none;
                        color: #fff;
                       
                    }
                }
            }
           
           
        }
    }
}
@media screen and (max-width:768px) {
    .container {
        padding: 1.5rem;
        .innerContainer {
           
            .left {
                p {
                    font-size: 1rem;
                    font-weight: bold;
                    text-transform: uppercase;
                    color:  rgba(144, 159, 186, 1);
                    margin-bottom: 1rem;
                }
                h2 {
                    width: 100%;
                    font-size: 4.5rem;
                    font-weight: bold;
                    text-transform: uppercase;
                    span {
                        color: rgba(8, 235, 182, 1);
                    }
                }
                .icons {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    margin-top: 2rem;
                    color: #fff;
                    font-size: 1.5rem;
                    a {
                        text-decoration: none;
                        color: #fff;
                    }
                }
            }
           
           
        }
    }
}