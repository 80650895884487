.container {
    position: fixed;
    background-image: url("../assets/bg.svg");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: rgba(19, 22, 35, 1);
    z-index: 99;
    .head {
        transition: all 250ms ease-in-out;
        padding: 2rem 6rem;
    
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 99;
        a {
            text-decoration: none;
            display: flex;
            align-items: center;
            gap: 1rem;
            text-decoration: none;
            color: #fff;
            font-size: 1.2rem;
            transition: all 250ms ease-in-out;
       
            &:hover {
                filter:drop-shadow( 0 0 15px rgba(8, 255, 201, 0.95));
            }
         
        }
        .mobileBtn {
            display: none;
        }
    }
    .innerContainer {
       display: flex;
       justify-content: space-between;
       padding:3rem 0;
       max-width: 1240px;
       width: 90%;
       margin: 0 auto;
       transition: all 250ms ease;
       animation: animate 250ms linear forwards; 
      
       .lefttwo {
        width: 55%;
        display: none;
        flex-direction: column;
        gap: 1rem;
        
        p {
            font-size: 2rem;
            color: rgba(144, 159, 186, 1);
        }
        h2 {
            font-family: 'BebasNeueBold', sans-serif;
            font-size: 5rem;
            text-transform: uppercase;
            span {
                color: rgba(8, 255, 201, 1);
            }
        }
       
        .icons {
            display: flex;
            gap: 1rem;
            margin-bottom: 3rem;
            a {
                color: #fff;
                font-size: 1.5rem;
                transition: all 150ms ease-in-out;
                &:hover {
                    filter:drop-shadow( 0 0 15px rgba(255, 255, 255, 0.95));
                    transform: scale(1.1);
                }
            } 
        }
        .social {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            a {
                display: flex;
                gap: 1rem;
                align-items: center;
                text-decoration: none;
                color: #fff;
                transition: all 150ms ease-in-out;
                &:hover {
                        filter:drop-shadow( 0 0 15px rgba(255, 255, 255, 0.95));
                }
                svg {
                    font-size: 1.5rem;
                }
            }
        }
        .mini {
            display: none;
        }
       }
       .left {
        width: 55%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        
        p {
            font-size: 2rem;
            color: rgba(144, 159, 186, 1);
        }
        h2 {
            font-family: 'BebasNeueBold', sans-serif;
            font-size: 5rem;
            text-transform: uppercase;
            span {
                color: rgba(8, 255, 201, 1);
            }
        }
       
        .icons {
            display: flex;
            gap: 1rem;
            margin-bottom: 3rem;
            a {
                color: #fff;
                font-size: 1.5rem;
                transition: all 150ms ease-in-out;
                &:hover {
                    filter:drop-shadow( 0 0 15px rgba(255, 255, 255, 0.95));
                    transform: scale(1.1);
                }
            } 
        }
        .social {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            a {
                display: flex;
                gap: 1rem;
                align-items: center;
                text-decoration: none;
                color: #fff;
                transition: all 150ms ease-in-out;
                &:hover {
                        filter:drop-shadow( 0 0 15px rgba(255, 255, 255, 0.95));
                }
                svg {
                    font-size: 1.5rem;
                }
            }
        }
        .mini {
            display: none;
        }
       }
       .right {
        
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        position: relative !important;
        min-height: 600px;
        .rightLogo {
            position: absolute;
            bottom: 0;
            left:10%;
        }
        .phaseThree {
            position: relative;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .phaseThreeBg {
                z-index: -1;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
            }
        }
        .phaseFour {
            position: relative;
           
        }
       }

    }
    
    @keyframes animate {
        0% {
          opacity: 0;
          transform: scale(0.9);
          
        }
     
        100% {
          opacity: 1;
          transform: scale(1);

        }
      }
}
@media screen and (max-width: 1024px) {
    .container {
        overflow-y: auto;
       
        .head {
            padding: 2rem 3rem;
            button {
                display: none;
            }
          
           .mobileBtn {
            display: block;
            all: unset;
            font-size: 2rem;
            color: #fff;
            cursor: pointer;
           }
           
        }
        .innerContainer {
           padding:2rem 3rem;
           overflow-x: hidden;

           .left {
            width: 60%;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            p {
                font-size: 1.5rem;
            }
            h2 {
                font-size: 3rem;
               
            }
          
            .icons {
                margin-bottom: 1.5rem;
                
            }
            .social {
                gap: 1rem;
                
            }
           }
         
           .right {
        
            .rightLogo {
              
                left:-50%;
            }
           
           }
    
        }
      
    }
}
@media screen and (max-width: 768px) {
    .container {
        .head {
            padding: 1.5rem;
       
           a {
            font-size: 1rem;

                img {
                    width: 50px;
                }
            }
           
        }
        .innerContainer {
        overflow-y: auto;

           padding:3rem  1.5rem;
            flex-direction: column;
            .left {
                display: none;
            }
           .lefttwo {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            p {
                font-size: 1.5rem;
            }
            h2 {
                font-size: 3.5rem;
               
            }
          
            .icons {
                margin-bottom: 1.5rem;
                
            }
            .social {
                gap: 1rem;
                
            }
            .mini {
                display: block;
                width: 80%;
                margin: 2rem auto;
            }
           }
           .right {
            min-height: fit-content;
            img {
                height:400px;
            }
            .rightLogo {
                display: none;
                
                
            }
           
           }
    
        }
      
      
    }
}