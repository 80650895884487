@font-face {
  font-family: 'ApercuRegular'; 
  src: url('./assets/fonts/Apercu\ Regular.otf');
}
@font-face {
  font-family: 'ApercuBold'; 
  src: url('./assets/fonts/Apercu\ Bold.otf'); 
}
@font-face {
  font-family: 'BebasNeueBold'; 
  src: url('./assets/fonts/BebasNeueBold.otf'); 
}
*, *::before , *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --_background: var(--button-background, rgb(0 0 0 / .3));
  --_color: var(--button-color, rgb(var(--color-white)));
  --_padding-inline: var(--button-padding-block, 32px);
  --_padding-block: var(--button-padding-inline, 12px);
  --_gap: var(--button-gap, calc(var(--_padding-block) * 1.25));
  --_w: var(--button-width, fit-content);
  --border-size: 2px;
  --border-radius: 24px;
  --deg: 0deg;
  --hover-duration: .5s;
  --icon-color: var(--_color);
  --conic-gradient: conic-gradient( from var(--deg, 90deg), hsl(60deg 80% 80%), hsl(120deg 80% 80%), hsl(210deg 80% 80%), hsl(280deg 80% 80%), hsl(320deg 80% 80%), hsl(360deg 80% 80%), hsl(60deg 80% 80%) );
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'ApercuBold', sans-serif;
  font-family: 'ApercuRegular', sans-serif;
  background-color: rgba(19, 22, 35, 1);
  color: #fff;
  overflow-x: hidden;
  scroll-behavior: smooth;

}
// * {
//   border: 1px solid red !important;
// }

@media screen and (min-width:1024px) {
  .swiper-slide {
    width: 479px !important;
    height: 358px !important;
  }
}